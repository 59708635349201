import { Form, Button, Row, Col } from "react-bootstrap";
import { Controller, useForm, useFormState } from "react-hook-form";
import { Label } from "../Common/Label/Label";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import OpenSelect from "../Common/OpenTagSelect/OpenSelect";
import OpenDatePicker from "../Common/OpenDatePicker/OpenDatePicker";
import { profileForm } from "../../OpenInterfaces";
import { useBlocker, useNavigate } from "react-router-dom";
import HelperService from "../../Services/HelperService";
import ExitConfirmModal from "./ExitConfirmModal";

const suffinxOptions = [
  { id: "Jr.", value: "Jr." },
  { id: "Sr.", value: "Sr." },
  { id: "II", value: "II" },
  { id: "III", value: "III" },
  { id: "IV", value: "IV" },
  { id: "V", value: "V" },
  { id: "VI", value: "VI" },
  { id: "VII", value: "VII" },
  { id: "VIII", value: "VIII" },
  { id: "IX", value: "IX" },
  { id: "X", value: "X" },
];

interface propData {
  isUpdate: Function;
  tab?: string;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
}

const PersonalInfo = (props: propData) => {
  const { handleSubmit, register, watch, formState: { errors }, setValue, control, reset } = useForm<profileForm>({ mode: "onSubmit", reValidateMode: "onChange" });
  const navigate = useNavigate();
  const { dirtyFields } = useFormState({ control });
  const watchAllFields = watch();
  const [personalData, setPersonalData] = useState<any>([]);
  const [confirmExit, setConfirmExit] = useState(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.tab == "basics" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (props.tab == "basics" && !HelperService.isEmptyObject(dirtyFields)) {
      props.isFormDirty(true, 'basics');
    } else {
      props.isFormDirty(false, 'basics');
    }
  }, [dirtyFields, watchAllFields]);

  useEffect(() => {
    if (props.tab == "basics" && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange]);

  useEffect(() => {
    if (props.tab == "basics") {
      reset({});
      getPersonalInformation()
    };
  }, [props.tab]);

  useEffect(() => {
    if (personalData) {
      setValue("legal_first_name", personalData.legal_first_name || "");
      setValue("legal_middle_name", personalData.legal_middle_name || "");
      setValue("legal_last_name", personalData.legal_last_name || "");
      setValue("suffix", personalData.suffix || "");
      setValue("different_first_name", personalData.is_different_first_name || "");
      setValue("is_different_first_name", personalData.is_different_first_name || "");
      setValue("different_first_name", personalData.different_first_name || "");
      setValue("former_first_name", personalData.former_first_name || "");
      setValue("former_middle_name", personalData.former_middle_name || "");
      setValue("former_last_name", personalData.former_last_name || "");
      setValue("is_former_name", personalData.is_former_name || "");
      setValue("dob", personalData.dob || "");
    }
  }, [personalData]);

  const getPersonalInformation = () => {
    WebService.getAPI({
      action: `personal-information/detail`,
    })
      .then((res: any) => {
        setPersonalData(res.result);
      })
      .catch(() => { });
  };

  const onSave = async (data: any) => {
    await WebService.postAPI<any>({
      action: "save/personal-information",
      body: data,
      id: "login_btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        props.isUpdate();
        let tempData = { ...watchAllFields };
        reset({});
        reset(tempData);
      })
      .catch((e: any) => { });
  };

  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave(watch());
          WebService.removeLoader('Save_and_exit_btn');
        }
        reset({});
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col style={{ maxWidth: '624px' }}>
            <Form className="form-style-2">
              <h4 className="font-medium font-20 mb-3 pb-1">The basics</h4>
              <p className="font-14 text-secondary">
                If you would like to add or update your personal information,
                please update it in your profile.
              </p>
              <Form.Group className="mb-4">
                <Form.Label>
                  Legal first/given name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  className={`${watchAllFields.legal_first_name ? "border-brand" : ""}`}
                  value={watchAllFields.legal_first_name}
                  {...register("legal_first_name", {
                    required: false,
                  })}
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label> Do you have a nickname you go by? </Form.Label>
                <Form.Control
                  type="text"
                  className={`${watchAllFields.different_first_name ? "border-brand" : ""}`}
                  value={watchAllFields.different_first_name}
                  {...register("different_first_name", {
                    required: false,
                  })}
                />
                {errors.different_first_name && (
                  <div className="login-error">
                    <Label
                      title={"Please Enter First Name."}
                      modeError={true}
                    />
                  </div>
                )}
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>Middle name</Form.Label>
                <Form.Control
                  type="text"
                  className={`${watchAllFields.legal_middle_name ? "border-brand" : ""}`}
                  value={watchAllFields.legal_middle_name}
                  {...register("legal_middle_name", {
                    required: false,
                  })}
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>
                  Last/family/surname<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  className={`${watchAllFields.legal_last_name ? "border-brand" : ""}`}
                  value={watchAllFields.legal_last_name}
                  {...register("legal_last_name", {
                    required: false,
                  })}
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>Suffix</Form.Label>
                <Controller
                  control={control}
                  name="suffix"
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <div className="react-select1">
                      <OpenSelect
                        sakey="suffix"
                        isSearchable={true}
                        options={suffinxOptions}
                        selected={watchAllFields.suffix}
                        onChange={(e: any) => {
                          field.onChange(e.id);
                        }}
                        placeholder="Choose an option"
                      />
                    </div>
                  )}
                />
              </Form.Group>

              {/* <Form.Group className="mb-4">
                <p className="font-16">
                  Have you use any other names?
                  <span className="text-danger">*</span>
                </p>

                <Form.Check
                  type="radio"
                  id="yes2"
                  label="Yes"
                  value={"YES"}
                  {...register("is_former_name", { required: false })}
                />
                <Form.Check
                  type="radio"
                  id="no2"
                  label="No"
                  value={"NO"}
                  onClick={() => {
                    setValue("former_first_name", "");
                    setValue("former_middle_name", "");
                    setValue("former_last_name", "");
                  }}
                  {...register("is_former_name", { required: false })}
                />
              </Form.Group> */}

              {watchAllFields &&
                watchAllFields.is_former_name &&
                watchAllFields.is_former_name == "YES" && (
                  <>
                    <Form.Group className="mb-4">
                      <Form.Label>
                        First Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={watchAllFields.former_first_name}
                        {...register("former_first_name", {
                          required: false,
                        })}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Middle Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={watchAllFields.former_middle_name}
                        {...register("former_middle_name", {
                          required: false,
                        })}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>
                        Last Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={watchAllFields.former_last_name}
                        {...register("former_last_name", {
                          required: false,
                        })}
                      />
                    </Form.Group>
                  </>
                )}

              <Form.Group className="mb-4">
                <Form.Label>
                  Date of birth<span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="dob"
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <OpenDatePicker
                      selected={watchAllFields.dob}
                      // minData={new Date()}
                      maxData={new Date()}
                      onChange={(e: any) => field.onChange(e)}
                    />
                  )}
                />
                <span className="text-secondary font-12">
                  Date uses "month, day, year" format (e.g. August 1, 2002)
                </span>
              </Form.Group>
            </Form>
            <div className="text-center">
              <Button
                type="submit"
                id="login_btn"
                // disabled={!isValid}
                className="btn btn-brand-1 w-100"
              >
                Save
              </Button>

            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};
export default PersonalInfo;

import { Form, Button, Row, Col } from "react-bootstrap";
import { Controller, set, useForm, useFormState } from "react-hook-form";
import { Label } from "../Common/Label/Label";
import React, { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import OpenSelect from "../Common/OpenTagSelect/OpenSelect";
import HelperService from "../../Services/HelperService";
import { useBlocker, useNavigate } from "react-router-dom";
import ExitConfirmModal from "./ExitConfirmModal";

interface profileForm {
  country_id: any;
  state_id: any;
  street: string;
  apartment: string;
  city: string;
  zipcode: string;
  alternate_adress: string;
  alternate_country_id: any;
  alternate_state_id: any;
  alternate_street: string;
  alternate_apartment: string;
  alternate_city: string;
  alternate_zipcode: string;
  address_1: string;
  address_2: string;
  address_3: string;
  alternate_address_1: string;
  alternate_address_2: string;
  alternate_address_3: string;
  zipcode_obj: any;
  alternate_zipcode_obj: any;
}

interface propData {
  isUpdate: Function;
  tab?: string;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
}

const AddressInfo = (props: propData) => {
  const { handleSubmit, register, watch, setValue, control, reset } = useForm<profileForm>({ mode: "onSubmit", reValidateMode: "onChange" });
  const navigate = useNavigate();
  const { dirtyFields } = useFormState({ control });
  const watchAllFields = watch();
  const [countryOptions, setCountryOptions] = useState<any[]>([]);
  const [stateOptions, setStateOptions] = useState<any[]>([]);
  const [stateOptions1, setStateOptions1] = useState<any[]>([]);
  const [confirmExit, setConfirmExit] = useState(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.tab == "address" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (props.tab == "address" && !HelperService.isEmptyObject(dirtyFields)) {
      props.isFormDirty(true, 'address');
    } else {
      props.isFormDirty(false, 'address');
    }
  }, [dirtyFields, watchAllFields]);

  useEffect(() => {
    if (props.tab == "address" && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange])

  useEffect(() => {
    if (props.tab == "address") {
      reset({});
      getAddressDetail();
      getCountryOption();
    }
  }, [props.tab]);

  const getAddressDetail = () => {
    WebService.getAPI({ action: "personal/address-detail" })
      .then((res: any) => {
        if (res.addresses && res.addresses.length > 0) {
          let isMailingAddress: boolean = false;
          for (let i in res.addresses) {
            if (res.addresses[i].type == "PERMANENT_ADDRESS") {
              res.addresses[i].country_id &&
                getStateOption(res.addresses[i].country_id, "1");
              setValue("country_id", res.addresses[i].country_id);
              setValue("state_id", res.addresses[i].state_id);
              setValue("street", res.addresses[i].street);
              setValue("apartment", res.addresses[i].apartment);
              setValue("city", res.addresses[i].city);
              setValue("zipcode", res.addresses[i].zipcode);
              setValue("zipcode_obj", res.addresses[i].zipcode_obj);
              setValue("address_1", res.addresses[i].address_1);
              setValue("address_2", res.addresses[i].address_2);
              setValue("address_3", res.addresses[i].address_3);
            } else if (res.addresses[i].type == "MAILING_ADDRESS") {
              isMailingAddress = true;
              res.addresses[i].country_id &&
                getStateOption(res.addresses[i].country_id, "2");
              setValue("alternate_country_id", res.addresses[i].country_id);
              setValue("alternate_state_id", res.addresses[i].state_id);
              setValue("alternate_street", res.addresses[i].street);
              setValue("alternate_apartment", res.addresses[i].apartment);
              setValue("alternate_city", res.addresses[i].city);
              setValue("alternate_zipcode", res.addresses[i].zipcode);
              setValue("alternate_zipcode_obj", res.addresses[i].zipcode_obj);
              setValue(
                "alternate_address_1",
                res.addresses[i].address_1
              );
              setValue(
                "alternate_address_2",
                res.addresses[i].address_2
              );
              setValue(
                "alternate_address_3",
                res.addresses[i].address_3
              );
            }
          }
          if (isMailingAddress) {
            setValue("alternate_adress", "YES");
          } else {
            setValue("alternate_adress", "NO");
          }
        }
      })
      .catch((e: any) => { });
  };

  const getCountryOption = () => {
    WebService.getAPI({ action: "countries" })
      .then((res: any) => {
        if (res.list && res.list.length > 0) {
          const usIndex = res.list.findIndex(
            (item: any) => item.name === "United States"
          );
          if (usIndex !== -1) {
            const updatedList = [
              res.list[usIndex],
              ...res.list.slice(0, usIndex),
              ...res.list.slice(usIndex + 1),
            ];
            const temp: any[] = updatedList.map((item: any) => {
              return { ...item, ...{ value: item.name } };
            });
            setCountryOptions(temp);
          } else {
            const temp: any[] = res.list.map((item: any) => {
              return { ...item, ...{ value: item.name } };
            });
            setCountryOptions(temp);
          }
        }
      })
      .catch((e: any) => { });
  };

  const getStateOption = (id: string, type?: string) => {
    WebService.postAPI({ action: `states`, body: { country_id: id } })
      .then((res: any) => {
        if (res.list && res.list.length > 0) {
          const temp: any[] = res.list.map((item: any) => {
            return { ...item, ...{ value: item.name } };
          });
          if (type == "1") {
            setStateOptions(temp);
          } else if (type == "2") {
            setStateOptions1(temp);
          } else {
            setStateOptions(temp);
            setStateOptions1(temp);
          }
        }
      })
      .catch((e: any) => { });
  };

  const onSave = async (data: profileForm) => {
    const obj1: any = {
      country_id: data.country_id,
      state_id: data.state_id,
      street: data.street,
      apartment: data.apartment,
      city: data.city,
      zipcode: data.zipcode,
      address_1: data.address_1,
      address_2: data.address_2,
      address_3: data.address_3,
      type: "PERMANENT_ADDRESS",
    };
    let requestTemp: any[] = [obj1];

    if (data.alternate_adress == "YES") {
      const obj2: any = {
        country_id: data.alternate_country_id,
        state_id: data.alternate_state_id,
        street: data.alternate_street,
        apartment: data.alternate_apartment,
        city: data.alternate_city,
        zipcode: data.alternate_zipcode,
        address_1: data.alternate_address_1,
        address_2: data.alternate_address_2,
        address_3: data.alternate_address_3,
        type: "MAILING_ADDRESS",
      };
      requestTemp = [obj1, obj2];
    }

    await WebService.postAPI({
      action: "save/personal-address",
      body: { addresses: requestTemp },
      id: "save_address_btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        props.isUpdate();
        let tempData = { ...watchAllFields };
        reset({});
        reset(tempData);
      })
      .catch((e: any) => { });
  };

  const getZipCodeData = (zip: string, isAlternate?: boolean) => {
    WebService.getAPI({ action: `zipcode?zipcode=${zip}` })
      .then((res: any) => {
        if (res.result) {
          if (isAlternate) {
            setValue("alternate_city", res.result.city_id);
            setValue("alternate_state_id", res.result.city.state_id);
          } else {
            setValue("city", res.result.city_id);
            setValue("state_id", res.result.city.state_id);
          }
        }
      })
      .catch((e: any) => { });
  };

  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave(watch());
          WebService.removeLoader('Save_and_exit_btn');
        }
        reset({});
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col style={{ maxWidth: '624px' }}>
            <Form className="form-style-2">
              <h4 className="font-medium font-20 mb-3 pb-1">Address</h4>
              <Form.Label>
                Permanent home address<span className="text-danger">*</span>
              </Form.Label>
              <Form.Group className="mb-4">
                <Form.Label>
                  Country<span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="country_id"
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <>
                      <div className="react-select1">
                        <OpenSelect
                          sakey="country_id"
                          isSearchable={true}
                          placeholder="Choose an option"
                          options={countryOptions}
                          selected={watchAllFields.country_id}
                          onChange={(e: any) => {
                            field.onChange(e.id);
                            setValue("city", "");
                            setValue("zipcode", "");
                            setValue("state_id", "");
                            getStateOption(e.id);
                          }}
                        />
                      </div>
                    </>
                  )}
                />
              </Form.Group>

              {watchAllFields && watchAllFields.country_id && (
                <>
                  {watchAllFields.country_id == 239 && (
                    <>
                      <Row>
                        <Form.Group className="mb-4 col-lg-4">
                          <Form.Label>
                            Zip code<span className="text-danger">*</span>
                          </Form.Label>
                          <Controller
                            control={control}
                            name="zipcode"
                            rules={{ required: false }}
                            render={({ field }: any) => (
                              <Form.Control
                                type="text"
                                className={`${watchAllFields.zipcode ? "border-brand" : ""}`}
                                onKeyPress={(e) => HelperService.allowOnlyNumericValue(e)}
                                {...register("zipcode", { required: false })}
                                maxLength={5}
                              />
                            )}
                          />
                        </Form.Group>
                        <Form.Group className="mb-4 col-lg-4">
                          <Form.Label>
                            City<span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className={`${watchAllFields.city ? "border-brand" : ""}`}
                            value={watchAllFields.city}
                            {...register("city", { required: false })}
                            onKeyPress={(e) =>
                              HelperService.allowOnlyAlphabetsValues(e)
                            }
                          />
                        </Form.Group>
                        <Form.Group className="mb-4 col-lg-4">
                          <Form.Label>
                            State<span className="text-danger">*</span>
                          </Form.Label>
                          <Controller
                            control={control}
                            name="state_id"
                            rules={{ required: false }}
                            render={({ field }: any) => (
                              <div className="react-select1">
                                <OpenSelect
                                  sakey="state_id"
                                  isSearchable={true}
                                  options={stateOptions}
                                  selected={watchAllFields.state_id}
                                  onChange={(e: any) => { field.onChange(e.id); }}
                                  placeholder={"Select State"}
                                />
                              </div>
                            )}
                          />
                        </Form.Group>
                        <Form.Group className="mb-4 col-lg-6">
                          <Form.Label>
                            Street<span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className={`${watchAllFields.street ? "border-brand" : ""
                              }`}
                            value={watchAllFields.street}
                            {...register("street", {
                              required: false,
                            })}
                          />
                        </Form.Group>
                        <Form.Group className="mb-4 col-lg-6">
                          <Form.Label>Apartment</Form.Label>
                          <Form.Control
                            type="text"
                            className={`${watchAllFields.apartment ? "border-brand" : ""
                              }`}
                            value={watchAllFields.apartment}
                            {...register("apartment", {
                              required: false,
                            })}
                          />
                        </Form.Group>
                      </Row>
                    </>
                  )}
                </>
              )}

              {watchAllFields.country_id && watchAllFields.country_id != 239 && (
                <>
                  <Form.Group className="mb-4 col-lg-12">
                    <Form.Control
                      type="text"
                      className={`${watchAllFields.address_1 ? "border-brand" : ""
                        }`}
                      value={watchAllFields.address_1}
                      {...register("address_1", {
                        required: false,
                      })}
                      placeholder="Address line 1*"
                    />
                  </Form.Group>
                  <Form.Group className="mb-4 col-lg-12">
                    <Form.Control
                      type="text"
                      className={`${watchAllFields.address_2 ? "border-brand" : ""
                        }`}
                      value={watchAllFields.address_2}
                      {...register("address_2", {
                        required: false,
                      })}
                      placeholder="Address line 2"
                    />
                  </Form.Group>
                  <Form.Group className="mb-4 col-lg-12">
                    <Form.Control
                      type="text"
                      className={`${watchAllFields.address_3 ? "border-brand" : ""
                        }`}
                      value={watchAllFields.address_3}
                      {...register("address_3", {
                        required: false,
                      })}
                      placeholder="Address line 3 (if applicable)"
                    />
                  </Form.Group>
                  <Form.Group className="mb-4 col-lg-12">
                    <Form.Control
                      type="text"
                      className={`${watchAllFields.city ? "border-brand" : ""}`}
                      value={watchAllFields.city}
                      {...register("city", {
                        required: false,
                      })}
                      placeholder="City/Town/Settlement*"
                    />
                  </Form.Group>
                  <Form.Control
                    // className="mb-4"
                    className={`${watchAllFields.zipcode ? "border-brand mb-4" : "mb-4"
                      }`}
                    type="text"
                    onKeyPress={(e) => HelperService.allowOnlyNumericValue(e)}
                    {...register("zipcode", { required: false })}
                    placeholder="Postal code*"
                  />
                </>
              )}

              <Form.Group className="mb-4">
                <p className="font-16">
                  Alternate mailing address (e.g. if you attend a boarding
                  school or use a different mailing address)
                  <span className="text-danger">*</span>
                </p>

                <Form.Check
                  type="radio"
                  id="Noalternateaddress"
                  label="No alternate address"
                  value={"NO"}
                  checked={
                    watchAllFields.alternate_adress == "NO" ? true : false
                  }
                  {...register("alternate_adress", { required: false })}
                />
                <Form.Check
                  type="radio"
                  id="Sendmail"
                  label="Send mail to a temporary or alternate address"
                  value={"YES"}
                  checked={
                    watchAllFields.alternate_adress == "YES" ? true : false
                  }
                  {...register("alternate_adress", { required: false })}
                />
              </Form.Group>
              {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

              {watchAllFields.alternate_adress == "YES" && (
                <Form className="form-style-2">
                  <Form.Group className="mb-4">
                    <Form.Label>
                      Country<span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name="alternate_country_id"
                      rules={{ required: false }}
                      render={({ field }: any) => (
                        <>
                          <div className="react-select1">
                            <OpenSelect
                              sakey="alternate_country_id"
                              isSearchable={true}
                              placeholder="Choose an option"
                              options={countryOptions}
                              selected={watchAllFields.alternate_country_id}
                              onChange={(e: any) => {
                                field.onChange(e.id);
                                setValue("alternate_city", "");
                                setValue("alternate_zipcode", "");
                                setValue("alternate_state_id", "");
                                getStateOption(e.id, "2");
                              }}
                            />
                          </div>
                        </>
                      )}
                    />
                  </Form.Group>

                  {watchAllFields && watchAllFields.alternate_country_id && (
                    <>
                      {watchAllFields.alternate_country_id == 239 && (
                        <>
                          <Row>
                            <Form.Group className="mb-4 col-lg-4">
                              <Form.Label>
                                Zip code<span className="text-danger">*</span>
                              </Form.Label>
                              <Controller
                                control={control}
                                name="alternate_zipcode"
                                rules={{ required: false }}
                                render={({ field }: any) => (
                                  // <AsyncZipcodeSelect
                                  //   selected={watchAllFields.alternate_zipcode}
                                  //   selectedObj={
                                  //     watchAllFields.alternate_zipcode_obj
                                  //   }
                                  //   onChange={(e: any) => {
                                  //     field.onChange(e.value);
                                  //     setValue(
                                  //       "alternate_state_id",
                                  //       e.state_id
                                  //     );
                                  //   }}
                                  // />
                                  <Form.Control
                                    type="text"
                                    className={`${watchAllFields.alternate_zipcode ? "border-brand" : ""}`}
                                    onKeyPress={(e) => HelperService.allowOnlyNumericValue(e)}
                                    {...register("alternate_zipcode", { required: false })}
                                    maxLength={5}
                                  />
                                )}
                              />
                            </Form.Group>
                            <Form.Group className="mb-4 col-lg-4">
                              <Form.Label>
                                City<span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className={`${watchAllFields.alternate_city ? "border-brand" : ""}`}
                                value={watchAllFields.alternate_city}
                                {...register("alternate_city", {
                                  required: false,
                                })}
                                onKeyPress={(e) =>
                                  HelperService.allowOnlyAlphabetsValues(e)
                                }
                              />
                            </Form.Group>
                            <Form.Group className="mb-4 col-lg-4">
                              <Form.Label>
                                State<span className="text-danger">*</span>
                              </Form.Label>
                              <Controller
                                control={control}
                                name="alternate_state_id"
                                rules={{ required: false }}
                                render={({ field }: any) => (
                                  <div className="react-select1">
                                    <OpenSelect
                                      sakey="alternate_state_id"
                                      isSearchable={true}
                                      options={stateOptions1}
                                      selected={
                                        watchAllFields.alternate_state_id
                                      }
                                      onChange={(e: any) => {
                                        field.onChange(e.id);
                                        setValue("alternate_zipcode", "");
                                      }}
                                      placeholder={"Select State"}
                                    />
                                  </div>
                                )}
                              />
                            </Form.Group>
                            <Form.Group className="mb-4 col-lg-6">
                              <Form.Label>
                                Street<span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className={`${watchAllFields.alternate_street ? "border-brand" : ""}`}
                                value={watchAllFields.alternate_street}
                                {...register("alternate_street", {
                                  required: false,
                                })}
                              />
                            </Form.Group>
                            <Form.Group className="mb-4 col-lg-6">
                              <Form.Label>Apartment</Form.Label>
                              <Form.Control
                                type="text"
                                className={`${watchAllFields.alternate_apartment ? "border-brand" : ""}`}
                                value={watchAllFields.alternate_apartment}
                                {...register("alternate_apartment", {
                                  required: false,
                                })}
                              />
                            </Form.Group>
                          </Row>
                        </>
                      )}
                    </>
                  )}

                  {watchAllFields.alternate_country_id &&
                    watchAllFields.alternate_country_id != 239 && (
                      <>
                        <Form.Group className="mb-4 col-lg-12">
                          <Form.Control
                            type="text"
                            className={`${watchAllFields.alternate_address_1 ? "border-brand" : ""}`}
                            value={watchAllFields.alternate_address_1}
                            {...register("alternate_address_1", {
                              required: false,
                            })}
                            placeholder="Address line 1*"
                          />
                        </Form.Group>
                        <Form.Group className="mb-4 col-lg-12">
                          <Form.Control
                            type="text"
                            className={`${watchAllFields.alternate_address_2 ? "border-brand" : ""}`}
                            value={watchAllFields.alternate_address_2}
                            {...register("alternate_address_2", {
                              required: false,
                            })}
                            placeholder="Address line 2"
                          />
                        </Form.Group>
                        <Form.Group className="mb-4 col-lg-12">
                          <Form.Control
                            type="text"
                            className={`${watchAllFields.alternate_address_3 ? "border-brand" : ""}`}
                            value={watchAllFields.alternate_address_3}
                            {...register("alternate_address_3", {
                              required: false,
                            })}
                            placeholder="Address line 3 (if applicable)"
                          />
                        </Form.Group>
                        <Form.Group className="mb-4 col-lg-12">
                          <Form.Control
                            type="text"
                            className={`${watchAllFields.alternate_city ? "border-brand" : ""}`}
                            value={watchAllFields.alternate_city}
                            {...register("alternate_city", {
                              required: false,
                            })}
                            placeholder="City/Town/Settlement*"
                          />
                        </Form.Group>
                        <Form.Control
                          className={`${watchAllFields.alternate_zipcode ? "border-brand mb-4" : "mb-4"}`}
                          type="text"
                          onKeyPress={(e) =>
                            HelperService.allowOnlyNumericValue(e)
                          }
                          {...register("alternate_zipcode", {
                            required: false,
                          })}
                          placeholder="Postal code*"
                        />
                      </>
                    )}
                </Form>
              )}
            </Form>

            <div className="text-center">
              <Button
                type="submit"
                id="save_address_btn"
                className="btn btn-brand-1 w-100"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};
export default AddressInfo;

import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsFillGridFill } from "react-icons/bs";
import { BiSolidSearch } from "react-icons/bi";
import { HiFolderMinus } from "react-icons/hi2";
import { HiMiniUserGroup } from "react-icons/hi2";
import { PiCompassFill } from "react-icons/pi";
import { Modal, Form, Button } from "react-bootstrap"
import InstaIcon from '../../assets/images/insta-logo.svg'
import twitterIcon from '../../assets/images/twitter-x-logo.svg'
import fbIcon from '../../assets/images/facebook-icon.svg'
import snapIcon from '../../assets/images/Snapchat.svg'
import tikTokIcon from '../../assets/images/tik_tok.svg'
import googleIcon from '../../assets/images/google-icon.svg';
import Logo from '../../assets/images/open-logo.svg';
import avtarIcon from '../../assets/images/avtar-male.png';
import iconSetting from '../../assets/images/menu-icon/icon-setting.svg'
import iconFaq from '../../assets/images/menu-icon/icon-faq.svg'
import iconSupport from '../../assets/images/menu-icon/icon-support.svg'
import iconLogout from '../../assets/images/menu-icon/icon-logout.svg'
import iconAppplication from '../../assets/images/menu-icon/icon-application.svg'
import iconPlanner from '../../assets/images/menu-icon/icon-planner.svg'
import iconInvite from '../../assets/images/menu-icon/icon-invite.svg'
import iconMyDetails from '../../assets/images/menu-icon/icon-user-icon.svg'
import { LiaStarSolid } from "react-icons/lia";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { USER_LOGOUT, setDataInRedux } from "../../action/CommonAction";
import { userInfoInterface } from "../../OpenInterfaces";
import { RootState } from "../../config/Store";
import WebService from "../../Services/WebService";
import CometChatWebService from "../../Services/CometChatWebService";
import { useAuth0 } from '@auth0/auth0-react';
import LogoutModal from '../Common/LogoutModal/LogoutModal';

interface PropData {
    isToggle: boolean;
    clicked: Function;
};

const menuActiveItems = [
    {
        id: 1,
        include: [],
        link: "/dashboard"
    },
    {
        id: 2,
        include: [],
        link: "/search-university"
    },
    {
        id: 3,
        include: ['requirements-tracker/university'],
        link: "/requirements-tracker"
    },
    {
        id: 4,
        include: ['my-details/about-me', 'my-details/family', 'my-details/education', 'my-details/tests', 'my-details/extracurriculars', 'my-details/writing-samples'],
        link: "/my-details"
    },
    {
        id: 5,
        include: [],
        link: "/performance-tracker"
    },
    {
        id: 6,
        include: ['my-calendar'],
        link: "/my-planner"
    },
    {
        id: 7,
        include: [],
        link: "/documents"
    },
    {
        id: 8,
        include: [],
        link: "/my-connections"
    },
    {
        id: 9,
        include: [],
        link: "/feed"
    },
    {
        id: 10,
        include: [],
        link: "/invite-friends"
    },
    {
        id: 11,
        include: [],
        link: "/settings"
    },
];

const menuItems = [
    {
        id: 1,
        icon: <BsFillGridFill className='menu-icon' />,
        name: "Dashboard",
        link: "/dashboard"
    },
    {
        id: 2,
        icon: <BiSolidSearch className='menu-icon opacity-75' />,
        name: "University Matches",
        sub_text: "Coming soon",
        link: "/search-university"
    },
    {
        id: 3,
        icon: <img src={iconAppplication} style={{ width: "18px" }} className="menu-icon" />,
        name: "Requirements Tracker",
        link: "/requirements-tracker"
    },
    {
        id: 4,
        icon: <img src={iconMyDetails} style={{ width: "18px" }} className="menu-icon" />,
        name: "My Details",
        link: "/my-details"
    },
    {
        id: 5,
        icon: <LiaStarSolid className="menu-icon opacity-75" />,
        name: "Performance Tracker",
        sub_text: "Coming soon",
        link: "/performance-tracker"
    },
    {
        id: 6,
        icon: <img src={iconPlanner} className="menu-icon" style={{ width: "18px" }} />,
        name: "Planner",
        link: "/my-planner"
    },
    {
        id: 7,
        icon: <HiFolderMinus className="menu-icon opacity-75" />,
        name: "My Documents",
        sub_text: "Coming soon",
        link: "/documents"
    },
    {
        id: 8,
        icon: <HiMiniUserGroup className="menu-icon opacity-75" />,
        name: "My Network",
        sub_text: "Coming soon",
        link: "/my-connections"
    },
    {
        id: 9,
        icon: <PiCompassFill className="menu-icon opacity-75" />,
        name: "News & Updates",
        sub_text: "Coming soon",
        link: "/feed"
    }
];

const VerticalMenu = (props: PropData) => {
    const navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();
    const { logout } = useAuth0();
    const user_info: userInfoInterface = useSelector<RootState, userInfoInterface>((state: any) => state.commonData.user_info);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {// setShow(true)
    };
    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [isShowLogoutModal, setIsShowLogoutModal] = useState<boolean>(false);

    useEffect(() => {
        menuActiveItems.map((item, index) => {
            if (window.location.pathname === item.link) {
                setActiveIndex(index);
            } else if (item.include.length > 0) {
                item.include.map((sub_item) => {
                    if (window.location.pathname.includes(sub_item)) {
                        setActiveIndex(index);
                    }
                });
            }
        });
    }, [window.location.pathname]);

    useEffect(() => {
        setIsOpen(props.isToggle);
    }, [props.isToggle]);

    const onLogout = async () => {
        setIsShowLogoutModal(true);
    };



    return (
        <>
            <LogoutModal show={isShowLogoutModal} isClose={() => setIsShowLogoutModal(false)} />
            <div id="vertical_menu" className={`verticle-menu ${isOpen ? "open-menu" : ""}`}>
                <div className="text-center">
                    <img src={Logo} width={36} alt='' className='cursor-pointer' onClick={() => { setActiveIndex(0); navigate("/dashboard"); }} />
                </div>
                <div className="menu-wraper">
                    <div className="menu-list mt-4">
                        {
                            menuItems.map((item, index) => {
                                return (
                                    <Link onClick={() => { setActiveIndex(index); props.clicked(); }} title={item.name} id={`t-${item.id}`} to={item.sub_text ? "javascript:void(0)" : item.link} className={`menu-item ${activeIndex === index ? "active" : ""} ${item.sub_text ? "opacity-75" : ""}`} key={item.id}>
                                        {item.icon}
                                        <span className="menu-name">{item.name}
                                            {
                                                item.sub_text &&
                                                <>
                                                    <br />
                                                    <i className="text-italic menu-name m-0 opacity-75">{item.sub_text}</i>
                                                </>
                                            }
                                        </span>
                                    </Link>
                                )
                            })
                        }
                        {/* <Link onClick={() => props.clicked()} title="Dashboard" id="t-1" to={'/dashboard'} className="menu-item active">
                            <BsFillGridFill className='menu-icon' />
                            <span className="menu-name">Dashboard</span>
                        </Link>
                        <Link onClick={() => props.clicked()} title="University Matches" id="t-1" to={'/search-university'} className="menu-item">
                            <BiSolidSearch className="menu-icon" />
                            <span className="menu-name">University Matches</span>
                        </Link>
                        <Link onClick={() => props.clicked()} title="My Applications" id="t-1" to={'/requirements-tracker'} className="menu-item">
                            <img src={iconAppplication} style={{ width: "18px" }} className="menu-icon" />
                            <span className="menu-name">My Applications</span>
                        </Link>
                        <Link onClick={() => props.clicked()} title="My Details" id="t-1" to={'/my-details'} className="menu-item">
                            <img src={iconMyDetails} style={{ width: "14px" }} className="menu-icon" />
                            <span className="menu-name">My Details</span>
                        </Link>
                        <Link onClick={() => props.clicked()} title="My Details" id="t-1" to={'/performance-tracker'} className="menu-item">
                            <LiaStarSolid className="menu-icon" />
                            <span className="menu-name">Performance Tracker</span>
                        </Link>
                        <Link onClick={() => props.clicked()} title="Planner" id="t-1" to={'/my-planner'} className="menu-item"> <img src={iconPlanner} className="menu-icon" style={{ width: "18px" }} /><span className="menu-name">Planner</span></Link>
                        <Link onClick={() => props.clicked()} title="My Documents" id="t-1" to={'/documents'} className="menu-item"> <HiFolderMinus className="menu-icon" /><span className="menu-name">My Documents</span></Link>
                        <Link onClick={() => props.clicked()} title="My Connections" id="t-1" to={'/my-connections'} className="menu-item"> <HiMiniUserGroup className="menu-icon" /><span className="menu-name">My Connections</span></Link>
                        <Link onClick={() => props.clicked()} title="News & Updates" id="t-1" to={'/feed'} className="menu-item"> <PiCompassFill className="menu-icon" /><span className="menu-name">News & Updates</span></Link> */}
                        <a title="Invite Friends" id="t-1" onClick={handleShow} className="menu-item cursor-pointer opacity-75">
                            <img src={iconInvite} className="menu-icon" />
                            <span className="menu-name">Invite Friends
                                <br />
                                <i className="text-italic menu-name m-0 opacity-75">Coming soon</i></span>
                        </a>
                    </div>

                    <div className="menu-list bottom mt-auto">
                        <Link onClick={() => { setActiveIndex(10); props.clicked() }} title="Dashboard" id="t-1" to={'/settings'}
                            className={`menu-item ${activeIndex === 10 ? "active" : ""}`}
                        >
                            <img src={iconSetting} className='menu-icon' /> <span className="menu-name">Setting</span></Link>
                        <Link onClick={() => props.clicked()} title="Dashboard" id="t-2" to={'javascript:void(0)'} className="menu-item opacity-75"> <img src={iconFaq} className='menu-icon' /> <span className="menu-name">FAQs</span></Link>
                        <Link onClick={() => props.clicked()} title="Dashboard" id="t-3" to={'javascript:void(0)'} className="menu-item opacity-75"> <img src={iconSupport} className='menu-icon' /> <span className="menu-name">Help & Support</span></Link>
                        <Link onClick={() => onLogout()} title="Logout" id="t-logout" to={'javascript:void(0)'} className="menu-item"> <img src={iconLogout} className='menu-icon' /> <span className="menu-name">Log Out</span></Link>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} size='lg' centered scrollable>
                <Modal.Header closeButton className='pb-0'>
                    <Modal.Title className='font-24'>Invite friends and gain notoriety!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='font-14 mb-4'>Get at least 10 connections to join and become an Open ambassador!</p>
                    <h4 className='font-20 mb-4'>Share your unique link:</h4>
                    <div className="d-flex gap-3 form-style-2">
                        <Form.Group className="mb-4 overlap-label w-100" controlId="email">
                            <Form.Label>Deadline</Form.Label>
                            <Form.Control className='text-secondary' type="text" value="http://sample.open=education/?referral=link&share123=friends#earnpoints" />
                        </Form.Group>
                        <Button className=' btn-brand-1 text-nowrap px-5'>Copy link</Button>
                    </div>
                    <div className="text-center font-14 mb-3">
                        or share your link via social media
                    </div>
                    <div className="d-flex gap-3 mb-4">
                        <Button className='btn-outline-light w-100'> <img src={InstaIcon} width="22" />  </Button>
                        <Button className='btn-outline-light w-100'> <img src={twitterIcon} width="22" />  </Button>
                        <Button className='btn-outline-light w-100'> <img src={fbIcon} width="22" />  </Button>
                        <Button className='btn-outline-light w-100'> <img src={snapIcon} width="22" />  </Button>
                        <Button className='btn-outline-light w-100'> <img src={tikTokIcon} width="22" />  </Button>
                    </div>

                    <h4 className='font-20 mb-4'>or send invites directly:</h4>
                    <div className="d-flex gap-3 form-style-2 mb-4">
                        <Form.Group className=" overlap-label w-100" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control className='text-secondary' type="email" />
                        </Form.Group>
                        <Button className=' btn-brand-1 text-nowrap px-5' style={{ width: "160px" }}>Send invite</Button>
                    </div>
                    <div className="d-flex gap-3 form-style-2 mb-4">
                        <div className="form-control d-flex gap-3 justify-content-center align-items-center">
                            <img src={googleIcon} width={22} alt="" />
                            <img src={InstaIcon} width={22} alt="" />
                            <img src={fbIcon} width={22} alt="" />
                        </div>
                        <Button className=' btn-brand-1 text-nowrap' style={{ width: "160px" }}>Import contacts</Button>
                    </div>
                    <div>
                        <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-2">
                            <div className="d-flex gap-2">
                                <img src={avtarIcon} width="38" height="38" className=' rounded-circle object-fit-cover' />
                                <div>
                                    <p className='font-medium mb-0'>Sam Olsen</p>
                                    <p className='text-secondary m-0'>from Facebook contacts</p>
                                </div>
                            </div>
                            <Link to="" className='text-link'>+ Invite</Link>
                        </div>
                        <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-2">
                            <div className="d-flex gap-2">
                                <img src={avtarIcon} width="38" height="38" className=' rounded-circle object-fit-cover' />
                                <div>
                                    <p className='font-medium mb-0'>Sam Olsen</p>
                                    <p className='text-secondary m-0'>from Facebook contacts</p>
                                </div>
                            </div>
                            <Link to="" className='text-link'>+ Invite</Link>
                        </div>
                        <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-2">
                            <div className="d-flex gap-2">
                                <img src={avtarIcon} width="38" height="38" className=' rounded-circle object-fit-cover' />
                                <div>
                                    <p className='font-medium mb-0'>Sam Olsen</p>
                                    <p className='text-secondary m-0'>from Facebook contacts</p>
                                </div>
                            </div>
                            <Link to="" className='text-link'>+ Invite</Link>
                        </div>
                        <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-2">
                            <div className="d-flex gap-2">
                                <img src={avtarIcon} width="38" height="38" className=' rounded-circle object-fit-cover' />
                                <div>
                                    <p className='font-medium mb-0'>Sam Olsen</p>
                                    <p className='text-secondary m-0'>from Facebook contacts</p>
                                </div>
                            </div>
                            <Link to="" className='text-link'>+ Invite</Link>
                        </div>
                        <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-2">
                            <div className="d-flex gap-2">
                                <img src={avtarIcon} width="38" height="38" className=' rounded-circle object-fit-cover' />
                                <div>
                                    <p className='font-medium mb-0'>Sam Olsen</p>
                                    <p className='text-secondary m-0'>from Facebook contacts</p>
                                </div>
                            </div>
                            <Link to="" className='text-link'>+ Invite</Link>
                        </div>
                        <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-2">
                            <div className="d-flex gap-2">
                                <img src={avtarIcon} width="38" height="38" className=' rounded-circle object-fit-cover' />
                                <div>
                                    <p className='font-medium mb-0'>Sam Olsen</p>
                                    <p className='text-secondary m-0'>from Facebook contacts</p>
                                </div>
                            </div>
                            <Link to="" className='text-link'>+ Invite</Link>
                        </div>
                    </div>


                </Modal.Body>

            </Modal>
        </>
    )
}
export default VerticalMenu;
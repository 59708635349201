import { Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Label } from "../Common/Label/Label";
import WebService from "../../Services/WebService";
import OpenDatePicker from "../Common/OpenDatePicker/OpenDatePicker";
import OpenUniversitySelect from "../Common/OpenTagSelect/OpenUniversitySelect";
import HelperService from "../../Services/HelperService";
import editIcon from "../../assets/images/icon-edit.svg";
import moment from "moment";
import AddSchoolAddress from "./AddSchoolAddress";
import { useBlocker, useNavigate } from "react-router-dom";
import ExitConfirmModal from "./ExitConfirmModal";
import AsyncUnivarsityPaginationSelect from "../Common/OpenTagSelect/AsyncUnivarsityPaginationSelect";

interface propData {
  isUpdate: Function;
  tab?: string;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
}

const RecentSchool = (props: propData) => {
  const { handleSubmit, register, watch, setValue, control, reset, formState: { errors } } = useForm<any>({ mode: "onSubmit", reValidateMode: "onChange" });
  const navigate = useNavigate();
  const { dirtyFields } = useFormState({ control });
  const watchAllFields = watch();
  const [openSchoolAddress, setOpenSchoolAddress] = useState<boolean>(false);
  const [confirmExit, setConfirmExit] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.tab == "recent" && (!HelperService.isEmptyObject(dirtyFields) || isUpdate) && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (props.tab == "recent" && (!HelperService.isEmptyObject(dirtyFields) || isUpdate)) {
      props.isFormDirty(true, 'recent');
    } else {
      props.isFormDirty(false, 'recent');
    }
  }, [dirtyFields, watchAllFields, isUpdate]);

  useEffect(() => {
    if (props.tab == "recent" && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange]);

  useEffect(() => {
    if (props.tab == "recent") {
      reset({});
      getRecentSchoolDetail();
    }
  }, [props.tab]);

  const getRecentSchoolDetail = () => {
    WebService.getAPI({ action: `get/current-recent/school/details` })
      .then((res: any) => {
        reset(res.result);
        if (res.result?.current_school) {
          setValue("current_high_school", { ...res.result?.current_school, ...{ value: res.result?.current_school?.id, label: res.result?.current_school?.name } });
          if (res.result?.current_school?.type == "CUSTOM") {
            setValue("school_country_id", res.result?.current_school?.country_id);
            setValue("school_state_id", res.result?.current_school?.state_id);
            setValue("school_state_code", res.result?.current_school?.state_code);
            setValue("school_city", res.result?.current_school?.city);
            setValue("school_street", res.result?.current_school?.street);
            setValue("school_zip", res.result?.current_school?.zipcode);
            setValue("school_address_1", res.result?.current_school?.address_1);
            setValue("school_address_2", res.result?.current_school?.address_2);
            setValue("school_address_3", res.result?.current_school?.address_3);
          }
          if (res.result?.current_school.country_id == 239) {
            setValue(
              "school_address",
              `${res.result?.current_school?.address || ""}, ${res.result?.current_school?.city_obj?.name || ""
              }, ${res.result?.current_school?.state_obj?.code || ""}, ${res.result?.current_school?.zip || ""
              }`
            );
          } else {
            setTimeout(() => {
              setValue(
                "school_address",
                `${res.result?.current_school?.address_1 || ""}, ${res.result?.current_school?.city_obj?.name || ""
                }, ${res.result?.current_school?.zip || ""}`
              );
            }, 100);
          }
        }
      })
      .catch((e) => { });
  };

  const onSave = async (data: any) => {
    await WebService.postAPI({
      action: `save/user-current-recent/school/detail`,
      body: data,
      id: "recent_school_btn",
    })
      .then((res: any) => {
        setIsUpdate(false);
        props.isUpdate();
        toast.success(res.message);
        let tempData = { ...watchAllFields };
        reset({});
        reset(tempData);

      })
      .catch((e: any) => { });
  };

  const onClose = (type: boolean, data: any) => {
    if (type) {
      setValue("school_country_id", data.country_id);
      setValue("school_state_id", data.state_id);
      setValue("school_state_code", data.state_code);
      setValue("school_city", data.city);
      setValue("school_street", data.street);
      setValue("school_zip", data.zipcode);
      setValue("school_address_1", data.address_1);
      setValue("school_address_2", data.address_2);
      setValue("school_address_3", data.address_3);
      if (data.country_id == 239) {
        setValue(
          "school_address",
          `${data?.street || ""}, ${data?.city || ""}, ${data?.state_code || ""
          }, ${data?.zipcode || ""}`
        );
      } else {
        setValue(
          "school_address",
          `${data?.address_1 || ""}, ${data?.city || ""},${data?.zipcode || ""}`
        );
      }
    }
    setOpenSchoolAddress(false);
  };

  const onChangeSchool = (e: any) => {
    if (e && e.value) {
      if (isNaN(e.value as any)) {
        setValue("school_address", "");
        setTimeout(() => { setOpenSchoolAddress(true); }, 100);
      } else {
        setValue(
          "school_address",
          `${e?.address || ""}, ${e?.city_obj?.name ? `${e?.city_obj?.name},` : ""
          } ${e?.state_obj ? e?.state_obj?.code : e?.state || ""}, ${e?.zipcode || e?.zip || ""
          } `
        );
      }
    }
  };

  const checkExperiences = (val: string) => {
    setIsUpdate(true);
    let check = watchAllFields.affected_progression_high_secondary_school;
    let isExist: boolean = false;
    let isExistNoChange: boolean = false;
    if (check.length > 0) {
      check.map((item: any) => { if (item == val) { isExist = true; } else if (item == "NO_CHANGE_PROGRESSION") { isExistNoChange = true; } });
    } else {
      setValue("affected_progression_high_secondary_school", [val]);
      return;
    }

    if (val == "NO_CHANGE_PROGRESSION") {
      isExist ? setValue("affected_progression_high_secondary_school", []) : setValue("affected_progression_high_secondary_school", [val]);
    } else {
      if (isExist) {
        let temp = check.filter((item: any) => item != val);
        setValue("affected_progression_high_secondary_school", temp);
      } else {
        if (isExistNoChange) {
          setValue("affected_progression_high_secondary_school", [...check.filter((item: any) => item != 'NO_CHANGE_PROGRESSION'), val]);
        } else {
          setValue("affected_progression_high_secondary_school", [...check, val]);
        }
      }
    }
  }

  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave(watch());
          WebService.removeLoader('Save_and_exit_btn');
        }
        reset({});
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col className="form-style-2" style={{ maxWidth: "624px" }}>
            <h4 className="font-medium font-20 mb-3 pb-1">
              Most recent or current high/secondary school
            </h4>
            <Form.Group className="mb-4">
              <Form.Label>
                {" "}
                Most recent or current high/secondary school
                <span className="text-danger">*</span>{" "}
              </Form.Label>
              <Controller
                control={control}
                name={`current_high_school`}
                rules={{ required: false }}
                render={({ field }: any) => (
                  <div className="form-style  mb-3">
                    <AsyncUnivarsityPaginationSelect
                      placeholder="Choose an option"
                      sakey="current_high_school"
                      selected={watchAllFields.current_high_school}
                      type="SCHOOL"
                      onChange={(e: any) => {
                        onChangeSchool(e);
                        field.onChange(e);
                      }}
                      isCustom={true}
                    />
                  </div>
                )}
              />
              {errors.current_high_school && (
                <div className="login-error">
                  <Label
                    title={"Please Select Recent School"}
                    modeError={true}
                  />
                </div>
              )}
            </Form.Group>

            <Controller
              control={control}
              name={`school_address`}
              render={({ field }: any) => <></>}
              rules={{ required: true }}
            />
            {watchAllFields.current_high_school && watchAllFields.current_high_school.type == "CUSTOM" ? (
              <Form.Group className="mb-4">
                <InputGroup>
                  <Form.Control
                    className={`${watchAllFields.school_address
                      ? "border-brand border-end-0 rounded-end-0"
                      : "border-end-0 rounded-end-0"
                      }`}
                    type={"text"}
                    placeholder="School address"
                    disabled
                    value={watchAllFields.school_address}
                  />
                  <InputGroup.Text
                    id="basic-addon2"
                    className="cursor-pointer bg-transparent"
                    onClick={() => {
                      setOpenSchoolAddress(true);
                    }}
                  >
                    <img className="cursor-pointer" src={editIcon} alt="icon" />
                  </InputGroup.Text>
                </InputGroup>
                {errors.school_address && !watchAllFields.school_address && (
                  <div className="login-error">
                    <Label
                      title={"Please enter school address"}
                      modeError={true}
                    />
                  </div>
                )}
              </Form.Group>
            ) : (
              <Form.Group className="mb-4">
                <Form.Control
                  type="text"
                  className={`${watchAllFields.school_address ? "border-brand" : ""}`}
                  placeholder="School address"
                  disabled
                  value={watchAllFields.school_address}
                />
              </Form.Group>
            )}

            <Form.Group className="mb-4">
              <Form.Label>
                {" "}
                Year you started at this school
                <span className="text-danger">*</span>{" "}
              </Form.Label>
              <Controller
                control={control}
                name={`date_of_entry`}
                rules={{ required: false }}
                render={({ field }: any) => (
                  <OpenDatePicker
                    type="year"
                    placeholderText="Pick a year"
                    selected={
                      watchAllFields.date_of_entry ??
                      watchAllFields.date_of_entry
                    }
                    maxData={new Date()}
                    minData={HelperService.removeYear(new Date(), 8)}
                    onChange={(selectedDate: Date) =>
                      field.onChange(moment(selectedDate).format("YYYY"))
                    }
                  />
                )}
              />
              {errors.date_of_entry && (
                <div className="login-error">
                  <Label title={"Please Select Entry Year"} modeError={true} />
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>
                Is this a boarding school?<span className="text-danger">*</span>
              </Form.Label>
              <Form.Check
                type="radio"
                id="Yes"
                label="Yes"
                value={"YES"}
                {...register("is_boarding_school", { required: false })}
              />
              <Form.Check
                type="radio"
                id="No"
                label="No"
                value={"NO"}
                {...register("is_boarding_school", { required: false })}
              />
              {errors.is_boarding_school && (
                <div className="login-error">
                  <Label title={"Please Select Option"} modeError={true} />
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>
                {" "}
                Did or will you graduate from this school?
                <span className="text-danger">*</span>{" "}
              </Form.Label>
              <Form.Check
                type="radio"
                id="Yes"
                label="Yes"
                value={"YES"}
                {...register("will_graduate_from_this_school", {
                  required: false,
                })}
              />
              <Form.Check
                type="radio"
                id="No"
                label="No"
                value={"NO"}
                {...register("will_graduate_from_this_school", {
                  required: false,
                })}
              />
              {errors.will_graduate_from_this_school && (
                <div className="login-error">
                  <Label title={"Please Select Option"} modeError={true} />
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                {" "}
                Expected or graduation date
                <span className="text-danger">*</span>{" "}
              </Form.Label>
              <Controller
                control={control}
                name={`graduation_year`}
                rules={{ required: false }}
                render={({ field }: any) => (
                  <OpenDatePicker
                    type="year"
                    selected={
                      watchAllFields.graduation_year ??
                      watchAllFields.graduation_year
                    }
                    placeholderText={"Pick a year"}
                    minData={new Date()}
                    onChange={(e: any) => field.onChange(e)}
                  />
                )}
              />
              {errors.graduation_year && (
                <div className="login-error">
                  <Label
                    title={"Please Select Graduation Year"}
                    modeError={true}
                  />
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-4">
              <p className="font-16">
                Select any of the listed circumstances that have had an effect
                on your high school progression or subsequent experiences.
                Select all that fit.<span className="text-danger">*</span>
              </p>
              <Form.Check
                type="checkbox"
                id="graduateEarly"
                label="Did or will graduate early"
                value={"GRADUATE_EARLY"}
                checked={watchAllFields.affected_progression_high_secondary_school?.includes("GRADUATE_EARLY")}
                onClick={() => { checkExperiences('GRADUATE_EARLY') }}
              />
              <Form.Check
                type="checkbox"
                id="graduateLate"
                label="Did or will graduate late"
                value={"GRADUATE_LATE"}
                checked={watchAllFields.affected_progression_high_secondary_school?.includes("GRADUATE_LATE")}
                onClick={() => { checkExperiences('GRADUATE_LATE') }}
              />
              <Form.Check
                type="checkbox"
                id="akeTimeOff"
                label="Did or will take time off"
                value={"TIME_OFF"}
                checked={watchAllFields.affected_progression_high_secondary_school?.includes("TIME_OFF")}
                onClick={() => { checkExperiences('TIME_OFF') }}
              />
              <Form.Check
                type="checkbox"
                id="gapYear"
                label="Did or will take a gap year"
                value={"GAP_YEAR"}
                checked={watchAllFields.affected_progression_high_secondary_school?.includes("GAP_YEAR")}
                onClick={() => { checkExperiences('GAP_YEAR') }}
              />
              <Form.Check
                type="checkbox"
                id="Nothing"
                label="Nothing influencing my journey"
                value={"NO_CHANGE_PROGRESSION"}
                checked={watchAllFields.affected_progression_high_secondary_school?.includes("NO_CHANGE_PROGRESSION")}
                onClick={() => { checkExperiences('NO_CHANGE_PROGRESSION') }}
              />
              {errors.affected_progression_high_secondary_school && (
                <div className="login-error">
                  <Label title={"Please Select an Option"} modeError={true} />
                </div>
              )}{" "}
            </Form.Group>

            <div className="text-center">
              <Button
                type="submit"
                id="recent_school_btn"
                className="btn btn-brand-1 w-100"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </form>

      {openSchoolAddress && <AddSchoolAddress
        show={openSchoolAddress}
        isClose={onClose}
        schoolName={watchAllFields.current_high_school ? watchAllFields.current_high_school.label : ""}
        data={watchAllFields}
      />}
    </>
  );
};
export default RecentSchool;
